<template>
  <div class="container">
    <el-form v-model="form" label-width="100px">
      <el-form-item label="政策标题">
        <el-input v-model="form.declarationPolicyName" placeholder="请输入"></el-input>
        <!-- <el-autocomplete
          v-model="form.declarationPolicyName"
          :fetch-suggestions="querySearchDeclarationPolicyName"
          placeholder="请输入政策标题"
          @select="handleSelectDeclarationPolicyName"
        >
          <template slot-scope="{ item }">
            <div class="name">
              <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                item.declarationPolicyName
              }}</span>
            </div>
          </template>
        </el-autocomplete> -->
      </el-form-item>
      <el-form-item label="政策文件">
        <el-input v-model="form.fileUrl" placeholder="请输入"></el-input>
        <el-upload
          action
          class="upload-demo"
          ref="upload"
          :show-file-list="false"
          :before-upload="
            (res) => {
              return beforeUpload(res)
            }
          "
          :http-request="
            (res) => {
              return upload(res)
            }
          "
        >
          <el-button class="el_buttom" slot="trigger" type="primary" style="margin-left: 15px"
            >上传文件</el-button
          >
        </el-upload>
        <!-- <div class="file_flex" v-for="(el, i) in policyInterpretationFiles" :key="i">
          <div
            class="name_form"
            style="width: 600px; display: flex; justify-content: space-between"
          >
            <el-input v-model="el.name" placeholder="请上传政策文件" />
            <el-upload
              action
              class="upload-demo"
              ref="upload"
              :show-file-list="false"
              :before-upload="beforeUploadprods"
              :http-request="
                (res) => {
                  return uploadprods(res, i)
                }
              "
            >
              <el-button
                style="position: relative; left: -90px; border: none"
                slot="trigger"
                size="mini"
                type="primary"
                plain
                >上传附件</el-button
              >
            </el-upload>
          </div>
          <div style="display: flex; font-size: 24px">
            <span v-if="i == 0" style="color: #4e93fb" @click="addUpload"
              ><i class="el-icon-circle-plus"></i
            ></span>
            <span v-if="i > 0" style="color: #ff7575" @click="delUpload(i)"
              ><i class="el-icon-delete"></i
            ></span>
          </div>
        </div> -->
      </el-form-item>
      <el-form-item label="申报时间">
        <el-date-picker
          v-model="dateList"
          type="daterange"
          range-separator="至"
          start-placeholder="选择日期"
          end-placeholder="选择日期"
          @change="dateChanged"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <!-- <el-form-item label="行业类别">
        <el-checkbox-group v-model="tagList">
          <el-checkbox
            :label="item.value"
            v-for="(item, index) in tagList"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->
      <el-form-item label="申报地区">
        <el-select v-model="form.declarationProvince" placeholder="请选择省份" @change="citychange">
          <el-option :value="''" label="全部"></el-option>
          <el-option
            v-for="item in operatingList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <div style="display: inline-block; width: 10px"></div>
        <el-select v-model="form.declarationCity" placeholder="请选择地区">
          <el-option :value="''" label="全部"></el-option>
          <el-option
            v-for="item in operatingLists"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发布机构">
        <el-autocomplete
          class="inline-input"
          v-model="form.releaseUnit"
          :fetch-suggestions="enterpriseNameQuerySearch"
          placeholder="请输入"
          :trigger-on-focus="false"
          @input="releaseUnitChanged"
          @select="enterpriseNameSelect"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="网址">
        <el-input v-model="form.website" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="发文字号">
        <el-input v-model="form.publicationNumber" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="扶持标准">
        <el-input
          v-model="form.supportStandard"
          placeholder="请输入"
          type="textarea"
          :rows="5"
        ></el-input>
      </el-form-item>
      <el-form-item label="政策简介">
        <el-input
          v-model="form.declarationIntroduction"
          type="textarea"
          :rows="5"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="主题词">
        <el-input
          v-model="form.subjectWord"
          type="textarea"
          :rows="2"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary" @click="save" class="btn1">保存</el-button>
      <el-button @click="cancel" plain>取消</el-button>
    </div>
  </div>
</template>

<script>
import cos from '../../../../utils/cos.js'
import CitySelect from '@/components/CitySelect.vue'
import { selectPolicy, setPolicy, selectInstitution, getDataPolicy } from '@/api/policy.js'
import { selectByCompanyFullName } from '@/api/user.js'
import { apiUpload } from '@/api/content.js'
import { getByFatherIdt, getProvince } from '@/api/demand'

const defaultForm = Object.freeze({
  createId: 0, //
  createTime: '',
  declarationCity: '', // 申报城市
  declarationCountry: '中国', // 申报国家
  declarationEndTime: '', // 政策结束时间
  declarationIntroduction: '', // 政策简介
  declarationPolicyName: '', // 申报政策名称
  declarationProvince: '', // 申报省份
  declarationStartTime: '', // 政策开始时间
  fileUrl: '', // 文件地址
  id: null, // 新增传null,编辑传id
  industryCategory: '', // 行业类别
  industryCategoryList: [], // 行业类别(,隔开)
  isFreeze: false, // 是否冻结  默认冻结
  publicationNumber: '', // 发文字号
  releaseUnit: '', // 发布单位
  subjectWord: '', // 主题词
  supportStandard: '', // 扶持标准
  updateId: 0, // 申报城市
  updateTime: '', // 申报城市
  website: '' // 网址
  // policyInterpretationFiles: [] //多个行业动态附件
})
export default {
  name: 'addPolicy',
  components: {},
  data() {
    return {
      id: 0,
      form: { ...defaultForm }, //表单填写的数据
      enterpriseNameList: [], //查询到的企业名称
      tagList: [], //行业类别标签集合
      dateList: [], //日期集合
      operatingList: [], //省
      operatingLists: [], //市
      declarationPolicyNameOption: []
      // policyInterpretationFiles: [
      //   {
      //     name: ''
      //   }
      // ] //内容附件数组
    }
  },
  watch: {},
  created() {
    this.incity()
    if (this.$route.query.echo == 1) {
      this.id = this.$route.query.id
      this.form.id = this.id
      this.search()
      this.$forceUpdate()
    }
  },
  methods: {
    // 政策标题模糊查询
    // async querySearchDeclarationPolicyName(queryString, cb) {
    //   if (this.id != 0) {
    //     cb(this.declarationPolicyNameOption)
    //   } else {
    //     if (this.form.declarationPolicyName) {
    //       const { data: res } = await getDataPolicy({ name: this.form.declarationPolicyName })
    //       if (res.resultCode == 200) {
    //         this.declarationPolicyNameOption = res.data
    //         cb(this.declarationPolicyNameOption)
    //       }
    //     } else {
    //       cb(this.declarationPolicyNameOption)
    //     }
    //   }
    // },
    // async handleSelectDeclarationPolicyName(item) {
    //   this.form.declarationPolicyName = item.declarationPolicyName
    //   for (let key in item) {
    //     if (this.id == 0) {
    //       if (item[key] != null && item[key] != undefined && item[key] != '') {
    //         console.log('item', item[key])
    //         this.form[key] = item[key]
    //       }
    //     }
    //   }
    // },

    // 保存处理文件数组
    dislodge() {
      if (this.policyInterpretationFiles.length > 0) {
        this.form.policyInterpretationFiles = this.policyInterpretationFiles.map(
          (item) => item.name
        )
      }
    },
    async search() {
      const { data: res } = await selectPolicy({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
        this.$set(this.form, 'declarationProvince', `${res.data.declarationProvince}`)
        if (this.form.declarationStartTime != null && this.form.declarationEndTime != null) {
          this.dateList.push(this.form.declarationStartTime, this.form.declarationEndTime)
        }
      } else {
        this.$message.error('获取政策详情失败！')
      }
    },
    dateChanged(val) {
      if (val != null) {
        this.$set(this.form, 'declarationStartTime', val[0])
        this.$set(this.form, 'declarationEndTime', val[1])
      } else {
        this.$set(this.form, 'declarationStartTime', null)
        this.$set(this.form, 'declarationEndTime', null)
      }
    },
    //输入框变化
    releaseUnitChanged() {
      this.enterpriseNameList = []
    },
    //选择发布机构
    async enterpriseNameSelect(item) {
      let id = ''
      const { data: res } = await selectInstitution({ name: item.value, id })
      if (res.resultCode === 200) {
        this.form.enterpriseName = res.data[0]
        this.enterpriseNameList = []
      } else {
        this.$message.error('查询失败！')
      }
    },
    //查询发布机构
    async enterpriseNameQuerySearch(str, cb) {
      if (str != '') {
        let id = ''
        const { data: res } = await selectInstitution({ name: str, id })
        if (res.resultCode === 200) {
          res.data.forEach((item) => {
            this.enterpriseNameList.push({
              value: item
            })
          })
          cb(this.enterpriseNameList)
        } else {
          this.$message.error('查询失败！')
        }
      }
    },
    //上传pdf
    async beforeUpload(file) {
      this.file = file
      this.filename = file.name

      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    async upload(params, index) {
      const form = new FormData()
      form.append('file', params.file)

      if (!this.beforeUpload) {
        const res = await apiUpload(form)
        if (res.data.resultCode === 200) {
          this.form.fileUrl = res.data.data
          this.$message.success('上传成功!')
        }
      }
    },
    //返回省
    searchProvince(val) {
      this.form.province = val
    },
    //返回城市
    searchCity(val) {
      this.form.city = val
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
      this.citychange(this.form.declarationProvince)
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.form.declarationProvince == o.name) {
          //this.form.declarationCity = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }
        }
      })
    },

    // 上传附件
    //上传动态pdf
    // async beforeUploadprods(file) {
    //   this.file = file
    //   this.filename = file.name
    //   let format = file.name.split('.')[1]

    //   let formatList = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']

    //   const extension = formatList.indexOf(format)
    //   if (extension == -1) {
    //     this.$message.warning('上传模板不支持此格式')
    //     return
    //   }
    //   let reader = new FileReader()
    //   reader.readAsDataURL(file)

    //   let that = this
    //   reader.onload = function () {
    //     that.fileData = reader.result
    //   }
    //   return false
    // },
    //  //上传政策文件
    //  uploadprods(params, index) {
    //   const loading = this.$loading({
    //     lock: true,
    //     text: 'Loading',
    //     spinner: 'el-icon-loading',
    //     background: 'rgba(0, 0, 0, 0.7)'
    //   })
    //   /* 直接调用 cos sdk 的方法 */
    //   cos
    //     .uploadFile({
    //       Bucket: 'ginseng-wisdom-1300257084' /* 填写自己的 bucket，必须字段 */,
    //       Region: 'ap-nanjing' /* 存储桶所在地域，必须字段 */,
    //       Key:
    //         'system_img/' +
    //         params.file.name /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
    //       Body: params.file, // 上传文件对象
    //       SliceSize:
    //         1024 *
    //         1024 *
    //         500 /* 触发分块上传的阈值，超过5MB 使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
    //     })
    //     .then((res) => {
    //       if (res.statusCode === 200) {
    //         this.policyInterpretationFiles[index].name = res.Location
    //         loading.close()
    //       }
    //     })
    //     .catch((err) => {
    //       loading.close()
    //       console.log('上传出错', err)
    //     })
    // },
    // //新增政策文件附件
    // addUpload() {
    //   this.policyInterpretationFiles.push({
    //     name: ''
    //   })
    // },
    // //删除政策文件附件
    // delUpload(index) {
    //   this.policyInterpretationFiles.splice(index, 1)
    // },

    //保存
    async save() {
      // this.dislodge()
      const { data: res } = await setPolicy(this.form)
      if (res.resultCode === 200) {
        if (this.$route.query.echo == 0) {
          this.$message.success('新增成功！')
          this.$router.push({
            name: 'policyManagement'
          })
        } else {
          this.$message.success('修改成功！')
          this.$router.push({
            name: 'policyManagement'
          })
        }
      } else {
        if (this.$route.query.echo == 0) {
          this.$message.error('新增失败！')
        } else {
          this.$message.error('修改失败！')
        }
      }
    },
    //取消
    cancel() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/diagnosis/policymanagement',
        query: {
          changeNum: true
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: calc(100vh - 150px);
  padding: 20px;
  overflow: auto;
  background-color: #fff;
}
::v-deep .name_form .el-input__inner {
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 550px;
}
.file_flex {
  margin-bottom: 10px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}
.el-form ::v-deep .el-form-item__label {
  color: #333;
  margin-right: 10px;
}
.el-form ::v-deep .el-form-item__content {
  display: flex;
}
.el-form-item ::v-deep .el-input {
  width: 560px;
}
.el-form-item ::v-deep .el-textarea {
  width: 560px;
}
.el-select ::v-deep .el-input {
  width: 200px;
}
.distpicker-address-wrapper ::v-deep select {
  width: 150px;
}
.distpicker-address-wrapper ::v-deep label {
  margin-right: 10px;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>